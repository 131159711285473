import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import TextLink from 'components/TextLink'

const PostPreview = ({ post, filters, classes }) => (
  <div className={classes.container}>
    <div className={`d-flex flex-column align-items-start ${classes.innerContainer}`}>
      <div className={classes.postPreviewHeader}>
        <h3>
          <TextLink text={post.title} href={post.path} classes={{ link: classes.postTitle}} includeArrow={false} />
        </h3>
        <div className={classes.postText}>{post.summary}</div>
      </div>
      <div className={classes.postPreviewFooter}>
        <hr />
        <div className='d-flex justify-content-between'>
          <div className={classes.tags}>
            {post.categories.map(c => (
              <span
                key={c}
                className={filters.includes(c) ? classes.selectedTag : undefined}
              >
                {c}
              </span>
            ))}
          </div>
          <div className={classes.postTextLink}>
            <TextLink text='READ MORE' href={post.path} />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default injectSheet(style)(PostPreview)
