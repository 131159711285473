import React from 'react'
import injectSheet from 'react-jss'
import styles from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'gatsby-link'

const PaginationControls = ({ firstPage, pageCount, currentPage, classes }) => {
  return (
    <div className={classes.container}>
      <div>
        <span className={firstPage ? classes.disabledArrow : classes.activeArrow}>
          {firstPage ? (
            <FontAwesomeIcon icon='chevron-left' />
          ) : (
            <Link
              className={classes.red}
              to={currentPage === 1 ? '/blog' : `/blog/${currentPage}`}
            >
              <FontAwesomeIcon icon='chevron-left' />
            </Link>
          )}
        </span>
        {Array.from({ length: pageCount }).map((_, i) => {
          const isCurrent = currentPage === i
          const pageValue = i + 1
          return (
            <span
              key={i}
              className={isCurrent ? classes.activePageCount : classes.pageCount}
            >
              {isCurrent ? (
                pageValue
              ) : (
                <Link
                  className={classes.red}
                  to={i === 0 ? '/blog' : `/blog/${pageValue}`}
                >
                  {pageValue}
                </Link>
              )}
            </span>
          )
        })}
        <span
          className={
            currentPage === pageCount - 1 ? classes.disabledArrow : classes.activeArrow
          }
        >
          {currentPage === pageCount - 1 ? (
            <FontAwesomeIcon icon='chevron-right' />
          ) : (
            <Link className={classes.red} to={`/blog/${currentPage + 2}`}>
              <FontAwesomeIcon icon='chevron-right' />
            </Link>
          )}
        </span>
      </div>
    </div>
  )
}

export default injectSheet(styles)(PaginationControls)
