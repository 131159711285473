import React from 'react'
import TextLink from 'components/TextLink'
import injectSheet from 'react-jss'
import style from './styles'

const FeaturedPost = ({
  post: { path, title, summary, categories },
  filters,
  classes
}) => (
  <div className='container'>
    <div className={classes.container}>
      <div className={`col-md-5 col-12 ${classes.title}`}>
        <h2>
          <a className={classes.postTitle} href={path}>
            {title}
          </a>
        </h2>
      </div>
      <div className={`col-md-7 col-12 ${classes.snippet}`}>{summary}</div>
      <hr className={classes.separator} />
      <div className='d-flex justify-content-between'>
        <div className={classes.tags}>
          {categories.map(c => (
            <span
              key={c}
              className={filters.includes(c) ? classes.selectedTag : undefined}
            >
              {c}
            </span>
          ))}
        </div>
        <div className={classes.textLink}>
          <TextLink href={path} text='READ MORE' />
        </div>
      </div>
    </div>
  </div>
)

export default injectSheet(style)(FeaturedPost)
