import { COLORS, FONT_SIZES, SPACING } from 'helpers/globals'
import { styleFromXl } from 'helpers/screens'

export default {
  container: {
    height: '100%',
    padding: SPACING.small
  },
  postTitle: {
    color: COLORS.black
  },
  innerContainer: {
    height: '100%',
    backgroundColor: COLORS.white
  },
  tags: {
    color: COLORS.grey,
    fontSize: FONT_SIZES.micro,
    fontWeight: 700,
    letterSpacing: SPACING.teeny,
    marginTop: SPACING.small,
    textTransform: 'uppercase',
    '& span:not(:last-child)': {
      borderRight: `1px solid ${COLORS.grey}`,
      paddingRight: SPACING.tiny,
      marginRight: SPACING.tiny
    },
    ...styleFromXl({
      fontSize: FONT_SIZES.regular
    })
  },
  selectedTag: {
    color: COLORS.red
  },
  postTextLink: {
    fontWeight: 700,
    marginTop: SPACING.small,
    '& a': {
      color: COLORS.red,
      fontSize: FONT_SIZES.micro,
      letterSpacing: SPACING.teeny,
      ...styleFromXl({
        fontSize: FONT_SIZES.regular
      })
    }
  },
  postPreviewHeader: {
    marginTop: '0.5rem',
    marginBottom: 'auto',
    padding: SPACING.base,
    '& h3': {
      marginTop: 0
    }
  },
  postPreviewFooter: {
    width: '100%',
    padding: `0 ${SPACING.base}`,
    paddingBottom: SPACING.base
  }
}
