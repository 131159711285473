import { COLORS, FONT_SIZES, SPACING } from 'helpers/globals'
import background from 'assets/images/featuredPostBG.jpg'
import { styleUpToMd, styleFromXl } from 'helpers/screens'

export default {
  container: {
    position: 'relative',
    zIndex: 2,
    backgroundColor: COLORS.black,
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: COLORS.white,
    marginTop: SPACING.small,
    padding: SPACING.large,
    ...styleUpToMd({
      padding: SPACING.base
    })
  },
  separator: {
    backgroundColor: COLORS.grey,
    borderColor: COLORS.grey,
    borderStyle: 'solid',
    color: COLORS.grey
  },
  postTitle: {
    color: COLORS.white
  },
  title: {
    verticalAlign: 'top',
    lineHeight: SPACING.large,
    '& h2': {
      margin: 0,
      marginBottom: SPACING.base
    }
  },
  snippet: {
    marginBottom: SPACING.base
  },
  tags: {
    color: COLORS.grey,
    fontSize: FONT_SIZES.micro,
    fontWeight: 700,
    letterSpacing: SPACING.teeny,
    marginTop: SPACING.small,
    textTransform: 'uppercase',
    '& span:not(:last-child)': {
      borderRight: `1px solid ${COLORS.grey}`,
      paddingRight: SPACING.tiny,
      marginRight: SPACING.tiny
    },
    ...styleFromXl({
      fontSize: FONT_SIZES.regular
    })
  },
  selectedTag: {
    color: COLORS.red
  },
  textLink: {
    fontWeight: 700,
    marginTop: SPACING.small,
    '& a': {
      color: COLORS.red,
      fontSize: FONT_SIZES.micro,
      letterSpacing: SPACING.teeny,
      ...styleFromXl({
        fontSize: FONT_SIZES.regular
      })
    }
  }
}
