import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import FeaturedPost from 'components/FeaturedPost'
import PostPreview from './PostPreview'
import PageNav from 'components/PageNav'
import { uniq, toUpper, pipe, prop, map, flatten, any } from 'ramda'
import PageLink from 'components/PageLink'
import { COLORS } from 'helpers/globals'

const prepProps = props => {
  const postsFontmatter = props.posts.map(
    edge => edge.node.frontmatter
  )
  const newProps = {
    postsFontmatter,
    categories: pipe(map(prop('categories')), flatten, uniq)(postsFontmatter)
  }
  return <Posts {...{ ...props, ...newProps }} />
}

class Posts extends React.Component {
  state = {
    postsFontmatter: this.props.postsFontmatter,
    filters: []
  }

  updateCategory = (category, closeDrawer) => {
    const { filters } = this.state
    if (filters.includes(category)) {
      this.setState({ filters: filters.filter(f => f !== category) })
    } else {
      this.setState({ filters: [...filters, category] })
    }
    // closeDrawer is passed down from a React.children.map for <Drawer /> mobile view
    typeof closeDrawer === 'function' && closeDrawer()
  }

  render = () => {
    const {
      updateCategory,
      props: { classes, categories },
      state: { postsFontmatter, filters }
    } = this
    return (
      <div className={classes.container}>
        <PageNav title='CATEGORIES:'>
          {categories.map(c => (
            <PageLink
              key={c}
              Text={_ => (
                <span style={{ color: filters.includes(c) ? COLORS.red : COLORS.grey }}>
                  {toUpper(c)}
                </span>
              )}
              onClick={updateCategory.bind(null, c)}
            />
          ))}
        </PageNav>
        <FeaturedPost post={this.props.featuredPost} filters={filters} />
        <div className={`container ${classes.postsContainer}`}>
          <div className={`${classes.fullWidth} row no-gutters align-items-stretch`}>
            {(filters.length
              ? postsFontmatter.filter(p => any(c => filters.includes(c))(p.categories))
              : postsFontmatter
            ).map((frontmatter, i) => (
              <div className='col-12 col-md-6 col-xl-4' key={i}>
                <PostPreview
                  post={frontmatter}
                  filters={filters}
                  image={frontmatter.image.childImageSharp.resolutions.src}
                  aspectRatio={frontmatter.image.childImageSharp.resolutions.aspectRatio}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default injectSheet(style)(prepProps)
