import { COLORS, SPACING } from 'helpers/globals'

export default {
  container: {
    background: COLORS.lightestGrey
  },
  postsContainer: {
    backgroundColor: COLORS.lightestGrey,
    padding: `${SPACING.small} 0`
  },
  fullWidth: {
    width: '100%'
  }
}
