import { COLORS, FONT_SIZES, SPACING } from 'helpers/globals'

export default {
  container: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: FONT_SIZES.large,
    background: COLORS.lightestGrey,
    paddingBottom: SPACING.small
  },
  arrow: {},
  activeArrow: {
    color: COLORS.red,
    marginRight: SPACING.tiny
  },
  disabledArrow: {
    color: COLORS.grey,
    marginRight: SPACING.tiny
  },
  pageCount: {
    marginRight: SPACING.tiny
  },
  red: {
    color: COLORS.red
  },
  activePageCount: {
    fontWeight: 'bold',
    marginRight: SPACING.tiny
  }
}
