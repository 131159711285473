import React from 'react'
import CallToActionBlock from 'components/CallToActionBlock'
import PageHeader from 'components/PageHeader'
import Layout from 'components/Layout'
import Posts from './Posts'
import PaginationControls from './PaginationControls'
import { graphql } from 'gatsby'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import 'prismjs/themes/prism-okaidia.css'

const BlogListTemplate = ({
  data: {
    allMdx: { edges }
  },
  pageContext: { featuredPost, currentPage, pageCount }
}) => {
  const firstPage = currentPage === 0
  return (
    <Layout>
      <PageHeader title='Blog' />
      <Posts posts={firstPage ? edges.slice(1) : edges} featuredPost={featuredPost} />
      <PaginationControls {...{ firstPage, pageCount, currentPage }} />
      <CallToActionBlock
        title='We Have a Passion for Software'
        linkText='JOIN THE TEAM'
        linkHref='/careers'
      />
    </Layout>
  )
}

export const blogListTemplateQuery = graphql`
  query blogListTemplateQuery($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: $limit
      filter: {fileAbsolutePath: {regex: "/blog/"}}
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            date
            categories
            summary
            image {
              childImageSharp {
                resolutions(width: 600) {
                  src
                  aspectRatio
                }
              }
            }
          }
        }
      }
    }
  }
`
export default BlogListTemplate
